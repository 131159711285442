document.addEventListener('DOMContentLoaded', function (event) {
    const textElement = document.getElementById('text');
    const textArray = ["Software Engineer Student", "Programador Full Stack"];
    let arrayIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    let speed = 100;

    function type() {
        const currentText = textArray[arrayIndex];
        if (isDeleting) {
            charIndex--;
            textElement.textContent = currentText.substring(0, charIndex);
            speed = 50;
        } else {
            charIndex++;
            textElement.textContent = currentText.substring(0, charIndex);
            speed = 200;
        }

        if (!isDeleting && charIndex === currentText.length) {
            speed = 1500; // Pausa al final de la escritura
            isDeleting = true;
        } else if (isDeleting && charIndex === 0) {
            isDeleting = false;
            arrayIndex = (arrayIndex + 1) % textArray.length;
            speed = 500; // Pausa al comienzo de la siguiente frase
        }

        // Añadir clase para animar el cursor durante la escritura
        if (charIndex === currentText.length || charIndex === 0) {
            textElement.classList.remove('typing');
        } else {
            textElement.classList.add('typing');
        }

        setTimeout(type, speed);
    }

    setTimeout(type, speed);
    addEventListeners();
    darkMode();
});

function addEventListeners() {
    const mobileMenu = document.querySelector('.mobile-menu');

    mobileMenu.addEventListener('click', navegacionResponsive);
}

function darkMode() {
    const preferenciaDarkmode = window.matchMedia('(prefers-color-scheme:dark)');
    // console.log(preferenciaDarkmode.matches);
    const sectionProjects = document.querySelector('.container-proyectos');
    if (preferenciaDarkmode.matches) {
        document.body.classList.add('dark-mode');
    } else {
        document.body.classList.remove('dark-mode');
    }

    preferenciaDarkmode.addEventListener('change', function () {
        if (preferenciaDarkmode.matches) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    });

    const botondarkMode = document.querySelector('.dark-mode-boton');
    botondarkMode.addEventListener('click', function () {
        document.body.classList.toggle('dark-mode');
    });
}

function navegacionResponsive() {
    const navegacion = document.querySelector('.navegacion');
    if (navegacion.classList.contains('mostrar')) {
        navegacion.classList.remove('mostrar');
    }
    else {
        navegacion.classList.add('mostrar');
    }
}
